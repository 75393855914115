<template>
  <div class="dashboard">
    <!-- 合作机构区域，4种不同的颜色 -->
    <div class="center">
      <div class="hezuo">
        <div>
          <img src="../assets/image/xin.png" alt />
        </div>

        <div>
          <div class="hezuoshua">
            <div class="a_hezuoshua">52314</div>
            <div class="hezuotext">会员机构</div>
          </div>
        </div>
        .
        <div class="b_hezuoshua">家</div>
      </div>
      <div class="hezuo hezuoa">
        <div>
          <img src="../assets/image/chexingchejia.png" alt />
        </div>
        <div>
          <div class="hezuoshua">
            <div class="a_hezuoshua">52314</div>
            <div class="hezuotext">车型车价</div>
          </div>
        </div>
        <div class="b_hezuoshua">次</div>
      </div>

      <div class="hezuo hezuob">
        <div>
          <img src="../assets/image/kuaisugujia.png" alt />
        </div>
        <div>
          <div class="hezuoshua">
            <div class="a_hezuoshua">52314</div>
            <div class="hezuotext">快速估价</div>
          </div>
        </div>
        <div class="b_hezuoshua">次</div>
      </div>
      <div class="hezuo hezuoc">
        <div>
          <img src="../assets/image/che.png" alt />
        </div>
        <div>
          <div class="hezuoshua">
            <div class="a_hezuoshua">52314</div>
            <div class="hezuotext">登记状态</div>
          </div>
        </div>
        <div class="b_hezuoshua">条</div>
      </div>
      <div class="hezuo hezuod">
        <div>
          <img src="../assets/image/d.png" alt />
        </div>
        <div>
          <div class="hezuoshua">
            <div class="a_hezuoshua">52314</div>
            <div class="hezuotext">主体信息</div>
          </div>
        </div>
        <div class="b_hezuoshua">条</div>
      </div>
    </div>
    <!-- 合作机构区域，4种不同的颜色 -->
    <!-- 查询总量的开始 -->
    <div>
      <!-- 左边eharts表格 -->
      <div class="echart">
        <!-- biaoge -->
        <el-card class="left-echarts">
          <div class="first_echart">
            <div class="top_echart">查询总量趋势</div>
            <div class="first_echart_data">
              <div
                class="a_echart_data"
                v-for="(item, index) in date"
                @click="liClick(index)"
                :class="{ active: index === currentIndex }"
                :key="item.id"
              >
                {{ item.name }}
              </div>
              <!-- <div class="a_echart_data">本周</div>
              <div class="a_echart_data">本月</div>
              <div class="a_echart_data">全年</div>-->
            </div>
          </div>
          <!-- 为ECharts准备一个具备大小（宽高）的Dom -->
          <div ref="main" id="main" style="width: 650px; height: 226px"></div>
        </el-card>
        <!-- 右边echats表格 -->
        <el-card class="right-echarts">
          <div class="tit-right-echarts">注册用户使用排名</div>
          <div
            class="text-right-echarts"
            v-for="(item, i) in companyList"
            v-bind:key="i"
            :style="{ backgroundColor: item.background }"
          >
            <div class="id-echarts">{{ item.id }}</div>
            <div class="company-echarts">{{ item.company }}</div>
            <div class="company-num">{{ item.num }}</div>
          </div>
        </el-card>
      </div>
    </div>

    <!-- 查询总量的结束 -->

    <div></div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import Qs from "qs";
export default {
  data() {
    return {
      currentIndex: 0,
      value1: "",
      disabled: true,
      show: true,
      count: "",
      timer: null,
      date: [
        {
          id: 1,
          name: "本周",
        },
        {
          id: 2,
          name: "本月",
        },
        {
          id: 3,
          name: "全年",
        },
      ],
      companyList: [
        {
          id: 1,
          company: "泉州市风鹏汽配贸易有限公司",
          num: 427234,
          background: "#BFDFF7",
        },
        {
          id: 2,
          company: "北京德银天下供应链管理有限公司 ",
          num: 423234,

          background: "rgba(191, 223, 247, 0.8)",
        },
        {
          id: 3,
          company: "山西远行物流服务有限公司 ",
          num: 333234,

          background: "rgba(191, 223, 247, 0.6)",
        },
        {
          id: 4,
          company: "山西德银天下重型汽车销售有限公司 ",
          num: 324234,

          background: "rgba(191, 223, 247, 0.4)",
        },
        {
          id: 5,
          company: "临泉县华易汽车贸易有限公司 ",
          num: 323234,

          background: "rgba(191, 223, 247, 0.2)",
        },
      ],
    };
  },

  mounted() {
    this.createChart();
  },
  created() {
    this.getUserInfoList();
  },


  methods: {
    createChart() {
      console.log(111, this.$refs.main, document.getElementById("main"));
      var myChart = echarts.init(document.getElementById("main"));
      var option = {
        color: ["#FBD337", "#4ECB73", "#3AA0FF", "#D184FC"],
        legend: {
          bottom: 0,
          icon: "circle",
          left: 70,
        },
        tooltip: {},
        dataset: {
          dimensions: ["product", "车型", "快估", "登记状态", "主体信息"],
          source: [
            {
              product: "1月",
              车型: 43.3,
              快估: 85.8,
              登记状态: 93.7,
              主体信息: 93,
            },
            {
              product: "2月",
              车型: 83.1,
              快估: 73.4,
              登记状态: 55.1,
              主体信息: 93,
            },
            {
              product: "3月",
              车型: 86.4,
              快估: 65.2,
              登记状态: 82.5,
              主体信息: 93,
            },
            {
              product: "4月",
              车型: 72.4,
              快估: 53.9,
              登记状态: 39.1,
              主体信息: 93,
            },
            {
              product: "5月",
              车型: 72.4,
              快估: 53.9,
              登记状态: 39.1,
              主体信息: 93,
            },
            {
              product: "6月",
              车型: 72.4,
              快估: 53.9,
              登记状态: 39.1,
              主体信息: 93,
            },
            {
              product: "7月",
              车型: 72.4,
              快估: 53.9,
              登记状态: 39.1,
              主体信息: 93,
            },
          ],
        },
        xAxis: { type: "category" },
        yAxis: {},
        series: [
          { type: "bar" },
          { type: "bar" },
          { type: "bar" },
          { type: "bar" },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },

    liClick(index) {
      console.log(index);
      this.currentIndex = index;
    },
    // 点击主体上报发生路由跳转
    subjectReporting() {
      this.$router.push({
        path: "/subjectReport",
      });
    },
    // 点击登记上报发生路由跳转
    registerReport() {
      this.$router.push({
        path: "/registerReport",
      });
    },
    // 点击车型查询发生路由跳转
    vehicleInquiry() {
      this.$router.push({
        path: "/getCar",
      });
    },
    // 点击主体查询发生路由跳转
    subjectQuery() {
      this.$router.push({
        path: "/getSubject",
      });
    },
    // 点击登记查询发生路由跳转
    registrationInquiry() {
      this.$router.push({
        path: "/getRegister",
      });
    },
    phoneblur() {
      if (this.ruleForm.phone.length == 11) {
        this.disabled = false;
      }
    },

    // 获取用户信息列表
    async getUserInfoList() {
      const { data: res } = await this.$http.get("userInfo");
      if (res.code !== 200) return this.$message.error(res.consoleMsg);
      this.userlist = res.data;
      if (res.data.status === "first") {
        this.dialogVisible = true;
      }
    },
  },
};
</script>
<style lang="less">
.dashboard {
  .yanzhengma {
    display: flex;
  }
  .center {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    .hezuo {
      height: 100px;
      background: linear-gradient(270deg, #f961aa 0%, #da6ece 100%);
      box-shadow: 0px 0px 10px #bfdff7;
      opacity: 1;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;

      img {
        width: 30px;
        height: 30px;
        padding: 36px 20px 30px 16px;
      }
      .hezuoshua {
        vertical-align: middle;
        .a_hezuoshua {
          width: 90px;
          height: 42px;
          font-size: 30px;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 42px;
          color: #ffffff;
          opacity: 1;
        }
        .hezuotext {
          width: 90px;
          height: 20px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 20px;
          color: #ffffff;
          opacity: 1;
          text-align: center;
        }
      }
      .b_hezuoshua {
        width: 16px;
        height: 22px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 22px;
        color: #ffffff;
        opacity: 1;
        margin-right: 15px;
        margin-left: 4px;
      }
    }
    .hezuoa {
      height: 100px;
      background: linear-gradient(270deg, #faa143 0%, #f28464 100%);
      box-shadow: 0px 0px 10px #bfdff7;
      opacity: 1;
      border-radius: 10px;
    }
    .hezuob {
      height: 100px;
      background: linear-gradient(270deg, #d989ff 0%, #8f5ce1 100%);
      box-shadow: 0px 0px 10px #bfdff7;
      opacity: 1;
      border-radius: 10px;
    }
    .hezuoc {
      height: 100px;
      background: linear-gradient(270deg, #34e698 0%, #00b7bb 100%);
      box-shadow: 0px 0px 10px #bfdff7;
      opacity: 1;
      border-radius: 10px;
    }
    .hezuod {
      height: 100px;

      background: linear-gradient(270deg, #3aa0ff 0%, #00b7bb 100%);

      box-shadow: 0px 0px 10px #bfdff7;
      opacity: 1;
      border-radius: 10px;
    }
  }
  .echart {
    display: flex;
    margin-top: 20px;
    .left-echarts {
      flex: 2;
    }

    .first_echart {
      display: flex;
      font-size: 12px;
      color: #999;
      margin-top: 10px;
      justify-content: space-between;
      align-items: center;
      .top_echart {
        width: 120px;
        height: 28px;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 28px;
        color: #333333;
        opacity: 1;
      }
      .first_echart_data {
        display: flex;

        color: #999;

        .a_echart_data {
          width: 77px;
          height: 32px;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 32px;
          color: #999999;
          opacity: 1;
          border-radius: 10px;
          text-align: center;
        }
        .active {
          background: #1883e3;

          color: #ffffff;
        }
      }
    }
    .right-echarts {
      flex: 1.2;
      width: 100%;
      margin-left: 10px;

      background: #ffffff;
      opacity: 1;
      border-radius: 10px;
      .tit-right-echarts {
        width: 128px;
        height: 22px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 22px;
        color: #333333;
        opacity: 1;
      }
      .text-right-echarts {
        display: flex;
        justify-content: space-around;

        height: 40px;
        margin-top: 8px;
        line-height: 40px;
      }
      .id-echarts {
        flex: 0.5;
        text-align: center;
      }
      .company-echarts {
        flex: 2;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -webkit-text-overflow: ellipsis; //safari浏览器
        width: 300px;
      }
      .company-num {
        flex: 1;
        text-align: center;
      }
    }
  }
}
</style>
